import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/mobileAdaptation';
// import Vconsole from 'vconsole';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueLuckyCanvas from '@lucky-canvas/vue'
import 'mint-ui/lib/style.css'
import { Toast,MessageBox,InfiniteScroll,Spinner} from 'mint-ui'
Vue.use(InfiniteScroll);
Vue.component(Toast.name, Toast)
Vue.component(MessageBox.name, MessageBox)
Vue.component(Spinner.name, Spinner);


// Vue.use(new Vconsole())
Vue.use(VueLuckyCanvas)

Vue.config.productionTip = false

Vue.config.ignoredElements = [/^qa-router-button|templates|styles/]

Vue.use(ElementUI);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
