import { Base64 } from 'js-base64';
import md5 from 'js-md5';
import CryptoJS from 'crypto-js';
import * as pub from './public' 
// Base64 加密
export const Encryption = (param) => {
  return Base64.encodeURI(param);
}
// Base64 URL 加密
export const urlEncryption = (url, name) => {
  return name ? name + Encryption(url) : '/nd-8' + Encryption(url)
}
// Base64 URL 解密
export const urlDecode = (url, name) => {
  return name ? name + Base64.decode(url) : Base64.decode(url)
}
// 防抖
const debounce = (fn, delay = 300) => {
  let timer = null
  return (function (...args) {
    if (timer != null) {
      clearTimeout(timer)
      timer = null
    }
    timer = setTimeout(() => {
      fn.call(this, ...args)
    }, delay);
  })()
}
// 节流
export const throttle = (fn, delay = 300) => {
  let timer = null;
  return (() => {
    if (timer == null) {
      timer = setTimeout(() => {
        fn()
        clearTimeout(timer)
        timer = null
      }, delay)
    }
  })()
}
/**
 * 验证码倒计时
 * @param { ViewModel } $this 
 * @returns 无返回值
 * 需要Vue实例中有text属性与textShow 
 */
export const countDown = ($this) => {
  let timer = null;
  let time = 60;
  return (function () {
    $this.textShow = false;
    timer = setInterval(() => {
      time--;
      $this.text = time;
      if (time <= 0) {
        time = 60;
        $this.text = '重新获取';
        $this.textShow = true;
        clearInterval(timer);
      }
    }, 1000)
  })()
}
/**
 * 校验手机号
 * @param {*} phone 
 * @returns { Boolean }
 */
export const checkPhone = (phone) => {
  pub.isEmpty(phone) // 检查传参是否为空
  return true
}

export const validatePhoneNumber=(phoneNumber)=>{
  // 使用正则表达式进行手机号校验
  const phonePattern = /^1[3-9]\d{9}$/;
  return phonePattern.test(phoneNumber);
}
/**
 * AES-128-ECB 加密模式
 * @param { String | Number } param 参数
 * @return { String } 加密后的参数
 */
export const Encrypt = (param) => {
  let key = CryptoJS.enc.Utf8.parse('XJ20230110836055');
  let text = CryptoJS.enc.Utf8.parse(param);
  return CryptoJS.AES.encrypt(text,key,{
    mode:CryptoJS.mode.ECB,
    padding:CryptoJS.pad.Pkcs7
  }).toString();
}
/**
 * AES-128-ECB 解密模式
 * @param { String } text 加密文本
 * @return { String } 解密后的参数
 */
export function Decrypt(text) {
  let key = CryptoJS.enc.Utf8.parse("XJ20230110836055");
  let decrypt = CryptoJS.AES.decrypt(text, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return CryptoJS.enc.Utf8.stringify(decrypt)
}
// 公用加密
/**
 * AES-128-ECB 解密模式
 * @param { String } text 加密文本
 * @return { String } 解密后的参数
 */
export const pubEncrypt = (param,key) => {
  let _key = CryptoJS.enc.Utf8.parse(key);
  let text = CryptoJS.enc.Utf8.parse(param);
  return CryptoJS.AES.encrypt(text,_key,{
    mode:CryptoJS.mode.ECB,
    padding:CryptoJS.pad.Pkcs7
  }).toString();
}
/**
 * AES-128-ECB 解密模式
 * @param { String } text 加密文本
 * @return { String } 解密后的参数
 */
export function pubDecrypt(text,key) {
  let _key = CryptoJS.enc.Utf8.parse(key);
  let decrypt = CryptoJS.AES.decrypt(text, _key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return CryptoJS.enc.Utf8.stringify(decrypt)
}
// md5加密
export const pubMdf = (value) => {
  return md5(value);
}
// 对象转getUrl参数
export const getParamExtract = (param) => {
  let _urlDate = '?';
  for (const key in param) {
    if (Object.prototype.hasOwnProperty.call(param, key)) {
      const element = param[key];
      _urlDate += key + '=' + element + '&'
    }
  }
  // 返回长度-1 目的去掉最后的 &
  // console.log(_urlDate.substring(0, _urlDate.length - 1))
  return _urlDate.substring(0, _urlDate.length - 1);
}
// 栈结构 不采用es6写法
export function Stack() {
  this.items = [];
  // 向栈顶添加一个元素
  Stack.prototype.push = function (element) {
    this.items.push(element)
  }
  // 删除并返回栈顶元素
  Stack.prototype.pop = function () {
    return this.items.pop()
  }
  // 查看栈顶元素
  Stack.prototype.peek = function () {
    return this.items[this.items.length - 1]
  }
  // 查看栈中是否为空,为空就返回true
  Stack.prototype.isEmpty = function () {
    return this.items.length == 0
  }
  // 获取栈中元素的个数
  Stack.prototype.size = function () {
    return this.items.length
  }
  // toString 方法
  Stack.prototype.toString = function () {
    let resultString = ''
    for (let i = 0; i < this.items.length; i++) {
      i == this.items.length - 1 ? resultString += this.items[i] : resultString += this.items[i] + ' ';
    }
    return resultString;
  }
}

export default {
  Encryption,
  urlEncryption,
  urlDecode,
  debounce,
  countDown,
  throttle,
  checkPhone,
  Stack
}