/**
 * 判断一个参数是否为空
 * @param { * } param
 * @return { Boolean } 
 */
export const isEmpty = (param) => {
  switch (param) {
    case '':
    case undefined:
    case null:
    case NaN:
      throw new Error('该数据为空')
  }
  if (param instanceof Array && !param[0]) throw new Error('该数组为空');
  if (typeof(param) == 'object' && Object.keys(param).length == 0) throw new Error('该对象为空');
  return true;
}
/**
 * 获取随机数
 * @param { object } options 
 * 随机数最大值 max
 * 随机数最小值 min
 * 多少位随机数 count
 * @returns String
 */
export const random = (options={}) => {
  let {max,min,count} = options;
  !max ? max = 9 : ''; // 默认最大 9
  !min ? min = 0 : ''; // 默认最小 0
  !count ? count = 1 : ''; // 默认 1 个随机数
  let result = '';
  for (let index = 0; index < count; index++) {
    result += Math.floor(Math.random() * (max - min + 1)) + min
  }
  return result
}
// 生成随机字符串
export const generateRandomString = (length) => {
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}
// 存储数据到浏览器会话存储
export const setSessionStorage = (key, value) => {
  if (typeof value === 'object') {
    value = JSON.stringify(value);
  }
  window.sessionStorage.setItem(key, value);
}
// 从浏览器会话存储中获取数据
export const getSessionStorage = (key) => {
  let value = window.sessionStorage.getItem(key);
  if (value && value.startsWith('{') && value.endsWith('}')) {
    try {
      value = JSON.parse(value);
    } catch (e) {}
  }
  return value;
}
// 移除浏览器会话存储
export const removeSessionStorage = (key) => {
  window.sessionStorage.removeItem(key);
}
// 存储数据到浏览器本地存储
export const setLocalStorage = (key, value) => {
  if (typeof value === 'object') {
    value = JSON.stringify(value);
  }
  window.localStorage.setItem(key, value);
}
// 从浏览器本地存储中获取数据
export const getLocalStorage = (key) => {
  let value = window.localStorage.getItem(key);
  if (value && value.startsWith('{') && value.endsWith('}')) {
    try {
      value = JSON.parse(value);
    } catch (e) {}
  }
  return value;
}
// 移除存储数据到浏览器本地存储
export const removeLocalStorage = (key, value) => {
  window.localStorage.removeItem(key, value);
}
// 设置cookie
export const setCookie = (name, value, path) => { 
  //设置名称为name,值为value的Cookie
  var expdate = new Date();   //初始化时间
  expdate.setTime(expdate.getTime() + 12 * 30 * 24 * 60 * 60 * 1000);   // 一年
    document.cookie =name+'='+value+";expires="+expdate.toGMTString()+";path=/" + path;
}
// 读取cookie
export const getCookie = (c_name) => {
  //判断document.cookie对象里面是否存有cookie
  if (document.cookie.length>0){
    let c_start=document.cookie.indexOf(c_name + "=")
    //如果document.cookie对象里面有cookie则查找是否有指定的cookie，如果有则返回指定的cookie值，如果没有则返回空字符串
    if (c_start!=-1){ 
      c_start=c_start + c_name.length+1 
      let c_end=document.cookie.indexOf(";",c_start)
      if (c_end==-1) c_end=document.cookie.length
      return unescape(document.cookie.substring(c_start,c_end))
      } 
    }
  return ""
}