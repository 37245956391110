import Vue from 'vue'
import VueRouter from 'vue-router'
import { Base64 } from "js-base64"; // 引入加密
import { urlEncryption } from '../utils/utils'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '',
    meta: {
      title: '尼德霍格'
    },
    component: () => import('../views/nhdg')
  },
   //王者荣耀订购完成页面
   {
    path: '/orderCompleted',
    name: 'orderCompleted',
    meta: {
      title: '订单详情'
    },
    component: () => import('../views/orderCompleted')
  },
  //江苏联通爱奇艺（我的联通特惠）
  {
    path: '/cs_5js2lt7aiqiyi',
    name: 'cs_5js2lt7aiqiyi',
    meta: {
      title: '我的联通特惠'
    },
    component: () => import('../views/cs_5js2lt7aiqiyi')
  },
  //爱奇艺云盘（移动云盘爱奇艺优选会员）../views/cs_5aqy-27yp/nideINdex
  {
    path: '/cs_5aqy-27yp',
    name: 'cs_5aqy-27yp',
    meta: {
      title: '移动云盘爱奇艺优选会员'
    },
    component: () => import('../views/cs_5aqy-27yp')
  },
  //书香包 ok ../views/cs_5sxbao27/nideINdex

  {
    path: '/cs_5sxbao27',
    name: 'cs_5sxbao27',
    meta: {
      title: '书香包'
    },
    component: () => import('../views/cs_5sxbao27')
  },
  //广东联通-乐享月卡会员（芒果TV版）24.9元
  {
    path: '/cs_gdlt5-2lxyk7hy',
    name: 'cs_gdlt5-2lxyk7hy',
    meta: {
      title: '乐享月卡'
    },
    component: () => import('../views/cs_gdlt5-2lxyk7hy')
  },
  //CCTV手机资讯14元（CCTV手机资讯体育包月）ok ../views/cs_cctv5-27sjzix-14y/nideINdex
  {
    path: '/cs_cctv5-27sjzix-14y',
    name: 'cs_cctv5-27sjzix-14y',
    meta: {
      title: 'CCTV手机资讯体育包月'
    },
    component: () => import('../views/cs_cctv5-27sjzix-14y')
  },
  //CCTV手机资讯20元（CCTV手机资讯体育包月）
  {
    path: '/cs_cctv5-27sjzix-20y',
    name: 'cs_cctv5-27sjzix-20y',
    meta: {
      title: 'CCTV手机资讯体育包月'
    },
    component: () => import('../views/cs_cctv5-27sjzix-20y')
  },
  //激流快艇10元（g+盈正游戏包）ok   ../views/cs_5jlkt27-10y/nideINdex
  {
    path: '/cs_5jlkt27-10y',
    name: 'cs_5jlkt27-10y',
    meta: {
      title: 'g+盈正游戏包'
    },
    component: () => import('../views/cs_5jlkt27-10y')
  },
  //猪猪侠之传奇车神10元（畅想钻石会员）
  {
    path: '/cs_zzx5zhicq2cs7-10y',
    name: 'cs_zzx5zhicq2cs7-10y',
    meta: {
      title: '畅想钻石会员'
    },
    component: () => import('../views/cs_zzx5zhicq2cs7-10y')
  },
   //咪咕冒泡黄金会员（联合会员）
   {
    path: '/cs_mg5mp-gj27hy',
    name: 'cs_mg5mp-gj27hy',
    meta: {
      title: '联合会员'
    },
    component: () => import('../views/cs_mg5mp-gj27hy')
  },
   //咪咕冒泡悦读会（阅读联合会员）
   {
    path: '/cs_mg5mp-2ydh7',
    name: 'cs_mg5mp-2ydh7',
    meta: {
      title: '阅读联合会员'
    },
    component: () => import('../views/cs_mg5mp-2ydh7')
  },
  //WPS白金会员（WPS会员）
  {
    path: '/cs_WPS-5bj27hy-20y',
    name: 'cs_WPS-5bj27hy-20y',
    meta: {
      title: 'WPS会员'
    },
    component: () => import('../views/cs_WPS-5bj27hy-20y')
  },
  //咪咕冒泡钻石会员（联合会员）
  {
    path: '/cs_mg5mp-zs27hy',
    name: 'cs_mg5mp-zs27hy',
    meta: {
      title: '联合会员'
    },
    component: () => import('../views/cs_mg5mp-zs27hy')
  },
  //咪咕冒泡白金会员（联合会员）
  {
    path: '/cs_mg5mp-2bj7hy',
    name: 'cs_mg5mp-2bj7hy',
    meta: {
      title: '联合会员'
    },
    component: () => import('../views/cs_mg5mp-2bj7hy')
  },
  //咪咕卓易初级会员（联合会员）
  {
    path: '/cs_mg5zy-27cjhy',
    name: 'cs_mg5zy-27cjhy',
    meta: {
      title: '联合会员'
    },
    component: () => import('../views/cs_mg5zy-27cjhy')
  },
  //萤石云悦享会（萤石权益包）
  {
    path: '/cs_ys5sy-2x7h',
    name: 'cs_ys5sy-2x7h',
    meta: {
      title: '萤石权益包'
    },
    component: () => import('../views/cs_ys5sy-2x7h')
  },
  //大图王者荣耀抽奖页面
  {
    path: '/wangzhe_2a2yzqzc3qljb6',
    name: 'wangzhe_2a2yzqzc3qljb6',
    meta: {
      title: '荣耀抽奖'
    },
    component: () => import('../views/wangzhe_2a2yzqzc3qljb6')
  },
  //丛盛王者荣耀抽奖页面
  {
    path: '/stochasticCS',
    name: 'stochasticCS',
    meta: {
      title: '王者荣耀'
    },
    component: () => import('../views/stochasticCS')
  },
  //斗鱼王者荣耀抽奖页面
  {
    path: '/douyu_TIMI_P',
    name: 'douyu_TIMI_P',
    meta: {
      title: '王者荣耀'
    },
    component: () => import('../views/douyu_TIMI_P')
  },
   //斗鱼王者荣耀抽奖页面审核
   {
    path: '/douyuTIMI',
    name: 'douyuTIMI',
    meta: {
      title: '王者荣耀'
    },
    component: () => import('../views/douyuTIMI')
  },
  //三体联通王者荣耀抽奖页面
  {
    path: '/Lt_ST-ND',
    name: 'Lt_ST-ND',
    meta: {
      title: '王者荣耀'
    },
    component: () => import('../views/Lt_ST-ND')
  },
  {
    path: '/deeplink',
    name: 'deeplink组件',
    meta: {
      title: 'deeplink快应用'
    },
    component: () => import('../views/deeplink')
  },

  {
    path: '/taskWall',
    name: 'taskWall',
    meta: {
      title: '任务中心'
    },
    component: () => import('../views/taskWall/taskWall.vue')
  },
  {
    path: '/huaweiPrivacyPolicy',
    name: 'huaweiPrivacyPolicy',
    meta: {
      title: '隐私政策'
    },
    component: () => import('../views/huaweiPrivacyPolicy/index.vue')
  },
  {
    path: '/draw',
    name: 'draw',
    meta: {
      title: '抽奖'
    },
    component: () => import('../views/taskWall/draw.vue')
  },
  // error
  {
    path: '/error', 
    name: '页面不存在',
    meta: {
      title: '页面不存在'
    },
    component: () => import('../views/error'),
  },
   {
    path: '/aiqiyi',
    name: '爱奇艺星钻卡',
    meta: {
      title: '爱奇艺星钻卡'
    },
    component: () => import('../views/aiqiyihuaka')
  },
 
  {
    path: '/yidonghuaka',
    name: '移动花卡29',
    meta: {
      title: '移动花卡29'
    },
    component: () => import('../views/yidonghuaka')
  },
  {
    path: '/yidonghuaka2',
    name: '移动花卡39',
    meta: {
      title: '移动花卡39',
    },
    component: () => import('../views/yidonghuaka2')
  },
   // 萌猫隐私政策H5页面
   ,{
    path: '/privacyAgreement',
    name: 'privacyAgreement',
    meta: {
      title: '隐私政策'
    },
    component: () => import('../views/privacyAgreement')
  },
  // 河北各页面
  // 随心看会员
  {
    path: urlEncryption('hebei'), // nd-8aGViZWk
    name: '随心看会员',
    meta: {
      title: '随心看会员'
    },
    component: () => import('../views/hebei'),
  },
  {
    path: urlEncryption('followHeart'), // nd-8Zm9sbG93SGVhcnQ
    name: '随心看会员2',
    meta: {
      title: '随心看会员'
    },
    component: () => import('../views/hebei/pages/followHeart'),
  },
  {
    path: urlEncryption('happyBuy'), // nd-8aGFwcHlCdXk
    name:'欢乐购金卡月包',
    meta:{
      title:'欢乐购金卡月包'
    },
    component:()=>import('../views/hebei/pages/happyBuy_month'),
  },
  {
    path: urlEncryption('llb2G_day'), // nd-8bGxiMkdfZGF5
    name:'流量日包2GB版',
    meta:{
      title:'流量日包2GB版'
    },
    component:()=>import('../views/hebei/pages/trafficPacket2G_day'),
  },
  {
    path: urlEncryption('llb5G_day'), // nd-8bGxiNUdfZGF5
    name:'流量日包5GB版',
    meta:{
      title:'流量日包5GB版'
    },
    component:()=>import('../views/hebei/pages/trafficPacket5G_day'),
  },
  {
    path: urlEncryption('llb10G_day'), // nd-8bGxiMTBHX2RheQ
    name:'流量7天包(10GB)',
    meta:{
      title:'流量7天包(10GB)'
    },
    component:()=>import('../views/hebei/pages/trafficPacket10G_day'),
  },
  {
    path: urlEncryption('llb5G_month'), // nd-8bGxiNUdfbW9udGg 
    name:'15元5G流量月包(24个月)',
    meta:{
      title:'15元5G流量月包(24个月)'
    },
    component:()=>import('../views/hebei/pages/trafficPacket5G_month'),
  },
  {
    path: urlEncryption('enjoyPacket'), // nd-8ZW5qb3lQYWNrZXQ
    name:'5G权益流量畅享包2022版',
    meta:{
      title:'5G权益流量畅享包2022版'
    },
    component:()=>import('../views/hebei/pages/enjoyPacket'),
  },
  {
    path: '/trafficPacket15G_month', // urlEncryption('llb15G_month')
    name: '河北电信30元流量包',
    meta: {
      title: '河北电信30元流量包'
    },
    component: () => import('../views/hebei/pages/trafficPacket15G_month'),
  },
  {
    path: '/trafficPacketAffairs', // urlEncryption('llb15G_month')
    name: 'trafficPacketAffairs',
    meta: {
      title: '订购详情'
    },
    component: () => import('../views/hebei/pages/trafficPacket15G_month/affairs'),
  },
  // 蒙牛抽奖各页
  {
    path: urlEncryption('mengniu'), // nd-8bWVuZ25pdQ
    name:'蒙牛抽奖首页',
    meta:{
      title:'蒙牛抽奖'
    },
    component:()=>import('../views/mengniuLottery')
  },
  {
    path: urlEncryption('integral'), // nd-8aW50ZWdyYWw
    name:'积分兑换页',
    meta:{
      title:'积分兑换'
    },
    component:()=>import('../views/mengniuLottery/integralExchange.vue')
  },
  {
    path: urlEncryption('success'), // nd-8c3VjY2Vzcw
    name:'兑换成功',
    meta:{
      title:'兑换成功'
    },
    component:()=>import('../views/mengniuLottery/success.vue')
  },
  // 快应用广告页
  {
    path: '/butterflynovel', // 
    name:'快应用',
    meta:{
      title:'快应用'
    },
    component:()=>import('../views/quickapp/butterflynovel.vue')
  },
  {
    path: '/quickapp',
    name: '快应用',
    meta: {
      title: '快应用',
    },
    component:()=>import('../views/quickapp/manualButter.vue')
  },
  {
    path: '/newbutter', // 
    name:'蝴蝶书苑',
    meta:{
      title:'蝴蝶书苑'
    },
    component:()=>import('../views/quickapp/newButter.vue')
  },
  {
    path: '/userAgreement', // 
    name:'用户协议',
    meta:{
      title:'用户协议'
    },
    component:()=>import('../views/quickapp/userAgreement.vue')
  },
  {
    path: '/privacyAgreement', //
    name:'隐私协议',
    meta:{
      title:'隐私协议'
    },
    component:()=>import('../views/quickapp/privacyAgreement.vue')
  },
  // 其他杂页
  {
    path: '/otherone', 
    name:'跳转中',
    meta:{
      title:'跳转中..。'
    },
    component:()=>import('../views/other/index.vue')
  },
  // 商品转发页
  {
    path: '/forward', 
    name:'跳转中',
    meta:{
      title:'跳转中..。'
    },
    component:()=>import('../views/other/forward.vue')
  },
  // 商品转发页2
  {
    path: '/commodity', 
    name:'兑换',
    meta:{
      title:'兑换'
    },
    component:()=>import('../views/other/commodity/index.vue')
  },
  // 商品转发页3
  {
    path: '/forwardtwo/:platform', 
    name:'跳转中',
    meta:{
      title:'跳转中..。'
    },
    component:()=>import('../views/other/forwardtwo/index.vue')
  },
  // 宽带老页面
  {
    path: '/broadband', 
    name:'宽带办理',
    meta:{
      title:'宽带办理'
    },
    component:()=>import('../views/broadband/indexOld.vue')
  },
  // 宽带新页面
  {
    path: '/broadbandNew', 
    name:'宽带办理',
    meta:{
      title:'宽带办理'
    },
    component:()=>import('../views/broadband/index.vue')
  },
  {
    path: '/iagreement', 
    name:'用户协议',
    meta:{
      title:'用户协议'
    },
    component:()=>import('../views/broadband/IAAgreement.vue')
  },
  // 传奇
  {
    path: '/nd-8' + Base64.encodeURI('jinzhuang'), // nd-8amluemh1YW5n
    name: 'jinzhuang',
    meta: {
      title: '金装裁决'
    },
    component: () => import('../views/chuanqi/jinzhuang.vue'),
    children: [
      // 虎牙
      {
        // path:'hg-8' + Base64.encodeURI('huya'), // hg-8aHV5YQ 
        path: 'huya',
        alias: '/hg-8amluemh1YW5naHV5YQ',
        name: "huya",
        meta: {
          title: '金装裁决'
        },
        component: () => import('../views/chuanqi/jinzhuang.vue'),
      },
      // 斗鱼
      {
        // path:'hg-8' + Base64.encodeURI('douyv'), // hg-8ZG91eXY 
        path: 'douyv',
        alias: '/hg-8amluemh1YW5nZG91eXY',
        name: "douyv",
        meta: {
          title: '金装裁决'
        },
        component: () => import('../views/chuanqi/jinzhuang.vue'),
      }
    ]
  },
  // 传奇老页面
  {
    path: '/nd-8' + Base64.encodeURI('chuanqidouyv'), // nd-8Y2h1YW5xaWRvdXl2
    name: 'chuanqidouyv',
    meta: {
      title: '金装裁决'
    },
    component: () => import('../views/chuanqi/douyv.vue'),
  },
  {
    path: '/nd-8' + Base64.encodeURI('chuanqihuya'), // nd-8Y2h1YW5xaWh1eWE
    name: 'chuanqihuya',
    meta: {
      title: '金装裁决'
    },
    component: () => import('../views/chuanqi')
  },
  // 测试页面
  {
    path: '/henan',
    name: 'henan',
    meta: {
      title: '河南'
    },
    component: () => import('../views/henan')
  },
  {
    path: '/suip-active',
    name: '',
    meta: {
      title: ''
    },
    component: () => import('../views/suip-active')
  },
  {
    path: '/getUserAgent',
    name: 'getUserAgent',
    meta: {
      title: '获取UA测试页'
    },
    component: () => import('../views/getUserAgent')
  },
  {
    path: '/orderSuccessful',
    name: 'orderSuccessful',
    meta: {
      title: '订单详情'
    },
    component: () => import('../views/affairs/successful')
  },
  {
    path: '/middlePage',
    name: 'middlePage',
    meta: {
      title: ''
    },
    component: () => import('../views/middlePage/index')
  },
  {
    path: '/huafei/exchange',
    name: '',
    meta: {
      title: '兑换中心'
    },
    component: () => import('../views/huafei/exchange/index')
  },
  {
    path: '/huafei/phonePayment',
    name: '',
    meta: {
      title: ''
    },
    component: () => import('../views/huafei/phonePayment/index')
  },
  {
    path: '/order/success',
    name: '',
    meta: {
      title: ''
    },
    component: () => import('../views/order/success')
  },
  {
    path: '/huafei/finish',
    name: '',
    meta: {
      title: ''
    },
    component: () => import('../views/huafei/finish/index')
  },
  {
    path: '/huafei/finish/fail',
    name: '',
    meta: {
      title: ''
    },
    component: () => import('../views/huafei/finish/fail')
  },
  {
    path: '/huafei/finish/success',
    name: '',
    meta: {
      title: ''
    },
    component: () => import('../views/huafei/finish/success')
  },
  {
    path: '/huafei/exchange/couponList',
    name: '话费兑换券',
    meta: {
      title: '话费兑换券'
    },
    component: () => import('../views/huafei/exchange/couponList')
  },{
    path: '/imgTool/payment',
    name: '',
    meta: {
      title: '会员充值'
    },
    component: () => import('../views/imgTool/payment')
  },{
    path: '/imgTool/login',
    name: '',
    meta: {
      title: '登录'
    },
    component: () => import('../views/imgTool/login')
  },{
    path: '/imgTool/home',
    name: '',
    meta: {
      title: '我的'
    },
    component: () => import('../views/imgTool/home')
  },{
    path: '/imgTool/imgIndex',
    name: '',
    meta: {
      title: '图片修复'
    },
    component: () => import('../views/imgTool/imgIndex')
  }
  ,{
    path: '/imgTool/privacy-agreement',
    name: '',
    meta: {
      title: '隐私政策'
    },
    component: () => import('../views/imgTool/privacy-agreement')
  },
 
  
  
  {
    path: '/imgTool/user-agreement',
    name: '',
    meta: {
      title: '服务协议'
    },
    component: () => import('../views/imgTool/user-agreement')
  },{
    path: '/imgTool/imageDeal',
    name: '',
    meta: {
      title: ''
    },
    component: () => import('../views/imgTool/imageDeal')
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

export default router
